/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
import api from '../axios.service'

export default {
  getProductWornOut(data) {

    return api.get(`product_worn_out/list?search=${data.search}&date=${data.date}&type=${data.type}&is_worn_out=${data.is_worn_out}&page=${data.page}&size=${data.size}&sortBy=${data.sortBy}&sortDesc=${data.sortDesc}`)
  },

  packageStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    // return api.post(`payment_package/get_package?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)

    return api.post(`payment_package/update/${data.employee_type_id}`, params, {})
  },
  getExportData(data){
    return api.get(`reconcile_report/exports_product_worn_out?search=${data.search}&date=${data.date}&type=${data.type}&is_worn_out=${data.is_worn_out}`)

  },
  packageUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`payment_package/get_package?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },
  packageDestroy(data) {

    return api.delete(`payment_package/get_package?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  /////////////////////////////////////////////

  getReportPackage(data) {

    return api.get(`report_package_credit/get_report_package_credit?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  showPoint(data){
    return api.get(`point/show?search=${data.search}&date=${data.date}&status=${data.OrderStatus}`)
  },

  pointStore(data) {
    let getFormData = data

    let params = new FormData();
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`point/store`, params, {})
  },

  pointUpdate(data) {
    let getFormData = data

    let params = new FormData();
    params.append('_method', 'PUT');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }
    return api.post(`point/update/${data.employee_type_id}`, params, {})
  },

  productWornOutDestroy(data,type) {
    let getFormData = data
    getFormData.type = type;

    let params = new FormData();
    params.append('_method', 'DELETE');
    for (var key in getFormData) {
      if (getFormData.hasOwnProperty(key)) {
        params.append(key, getFormData[key]);
      }
    }
    // let config = {
    //   header: {
    //     'Content-Type': 'multipart/form-data'
    //   }
    // }

    return api.post(`product_worn_out/delete/${data.product_amount_id}`, params, {})
  },

}
